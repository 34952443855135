// import { start } from "@anycable/turbo-stream";
import { Application } from "@hotwired/stimulus";
import Carousel from "bootstrap/js/src/carousel";
// import ahoy from "ahoy.js";
// import Carousel from "bootstrap/js/src/carousel";
// import "bootstrap/js/src/collapse";
// import "bootstrap/js/src/dropdown";
// import CookiesEuBanner from "cookies-eu-banner";
import { Autocomplete } from "stimulus-autocomplete";
import { registerControllers } from "stimulus-vite-helpers";
// import StimulusReflex from "stimulus_reflex";
// import uPlot from "uplot";
// import consumer from "./channels/consumer";
// import "./channels/main_channel";
// import controller from "./controllers/application_controller";
// This is your cable instance
// import cable from "cable";
// Explicitly activate stream source elements
// start(consumer);

const application = Application.start();
const controllers = import.meta.glob("./**/*_controller.(js|ts)", {
  eager: true,
});
registerControllers(application, controllers);

application.register("autocomplete", Autocomplete);

// Configure Stimulus development experience
application.debug = import.meta.env.MODE !== "production";
window.Stimulus = application;

window.Carousel = Carousel;

// application.consumer = consumer;

// window.Carousel = Carousel;
// window.uPlot = uPlot;

// if (import.meta.env.MODE !== "production") {
//   import("radiolabel").then((Radiolabel) =>
//     application.register("radiolabel", Radiolabel.default)
//   );
// }
// StimulusReflex.initialize(application, { controller, isolate: true });

// new CookiesEuBanner(function () {}, false);

// if (import.meta.env.MODE !== "development") {
//   ahoy.trackView();
//   ahoy.trackClicks("a, button, input[type=submit]");
//   ahoy.trackSubmits("form");
//   ahoy.trackChanges("input, textarea, select");
// }

// removes the hash from the end of the URL after sign-in
// var href = window.location.href;
// if (href[href.length - 1] === "#") {
//   if (typeof window.history.replaceState == "function") {
//     window.history.replaceState({}, "", href.slice(0, -1));
//   }
// }
